import { FC, useEffect, useRef, useState } from 'react'
import styles from './Notification.module.scss'
import { classNames } from 'src/ui/utils/classnames'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { useEscapeOnClose } from 'src/ui/hooks/useEscapeOnClose'
import { useTrans } from 'src/ui/hooks/useTrans'
import { NotificationContent } from './NotificationContent'
import { CloseIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { PortalNotification } from './PortalNotification'
import { NotificationTypes } from './NotificationTypes.model'
import { usePageWidgetsConfigurator } from 'src/ui/contexts/PageWidgetsProvider'
import { useMedia } from 'src/ui/hooks/useMedia'

interface Props {
  title: string
  description?: string
  type: NotificationTypes
  variant?: 'light' | 'dark'
  isOpen: boolean
  closeAutomaticallyAfter?: number
  onClose?: () => void
  ariaLabel?: string
  className?: string
  'data-testid'?: string
  'data-quantum-warning'?: string | undefined
}

export const Notification: FC<Props> = ({
  title,
  description,
  type,
  variant = 'light',
  isOpen,
  ariaLabel,
  closeAutomaticallyAfter,
  onClose,
  className,
  'data-testid': testId,
  'data-quantum-warning': quantumWarning,
}) => {
  const { trans } = useTrans(['common'])
  const timer = useRef<NodeJS.Timeout | null>(null)
  const isDarkVariant = variant === 'dark'

  useEscapeOnClose({ onClose })

  useEffect(() => {
    if (
      isUndefined(onClose) ||
      isUndefined(closeAutomaticallyAfter) ||
      isUndefined(isOpen)
    ) {
      return
    }

    timer.current = setTimeout(() => {
      onClose()
    }, closeAutomaticallyAfter)

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [closeAutomaticallyAfter, isOpen, onClose])

  const { isMobile } = useMedia()
  const { setFooterElement } = usePageWidgetsConfigurator({
    isElementVisible: isMobile,
  })

  const handleNotificationClose = () => {
    if (isUndefined(onClose)) {
      return
    }

    if (timer.current) {
      clearTimeout(timer.current)
    }

    onClose()
  }

  return (
    <PortalNotification isOpen={isOpen}>
      <div
        ref={setFooterElement}
        className={classNames(styles.notificationWrapper, className)}
        aria-label={ariaLabel}
        role="dialog"
        data-testid={testId}
        data-quantum-warning={quantumWarning}
      >
        <div
          className={classNames(
            styles.notification,
            isDarkVariant ? styles.darkVariant : styles[type],
          )}
          onClick={e => e.stopPropagation()}
        >
          {onClose && (
            <button
              className={styles.exitButton}
              onClick={handleNotificationClose}
              aria-label={trans('modal_close-button_aria-label')}
            >
              <Icon
                size="m"
                color={isDarkVariant ? 'icon-light' : 'icon-dark'}
                icon={CloseIcon}
              />
            </button>
          )}
          <NotificationContent
            title={title}
            description={description}
            type={type}
            isDarkVariant={isDarkVariant}
          />
        </div>
      </div>
    </PortalNotification>
  )
}
