import 'react-international-phone/style.css'
import React, { ForwardedRef, forwardRef, useState } from 'react'
import { PhoneInput, PhoneInputRefType } from 'react-international-phone'
import styles from './PhoneField.module.scss'
import composedStyles from './PhoneFieldCompose.module.scss'
import defaultStyles from './PhoneFieldDefault.module.scss'
import { CountryISOCode } from 'src/core/Shared/infrastructure/dto/CountryISOCode'
import { Text } from 'src/ui/components/atoms/Text'
import { classNames } from 'src/ui/utils/classnames'

export type PhoneFieldVariant = 'default' | 'composed'

interface PhoneFieldProps extends BasePhoneFieldProps {
  label?: string
  variant?: PhoneFieldVariant
  error?: { message?: string }
}

interface InternalPhoneFieldProps extends BasePhoneFieldProps {
  hasError: boolean
}

interface BasePhoneFieldProps {
  value?: string
  onChange?: (phone: string) => void
  defaultCountry: CountryISOCode
  preferredCountries?: CountryISOCode[]
  placeholder?: string
}

export const PhoneField = forwardRef<PhoneInputRefType, PhoneFieldProps>(
  function PhoneField(
    { label, variant = 'default', error, ...restProps },
    ref,
  ) {
    const isComposed = variant === 'composed'
    const PhoneInput = isComposed ? ComposedPhoneField : DefaultPhoneField
    const hasError = Boolean(error)
    const message = error?.message
    return (
      <div>
        {label && <div className={styles.label}>{label}</div>}
        <PhoneInput ref={ref} hasError={hasError} {...restProps} />
        {message && (
          <Text fontStyle="s-300" color="support-error-dark">
            {message}
          </Text>
        )}
      </div>
    )
  },
)

const ComposedPhoneField = forwardRef(function ComposedPhoneField(
  { hasError, ...restProps }: InternalPhoneFieldProps,
  ref: ForwardedRef<PhoneInputRefType>,
): JSX.Element {
  return (
    <PhoneInput
      ref={ref}
      className={composedStyles.phoneFieldContainer}
      countrySelectorStyleProps={{
        className: classNames(
          composedStyles.countrySelector,
          hasError && composedStyles.countrySelectorError,
        ),
        buttonClassName: composedStyles.countrySelectorButton,
        dropdownArrowClassName: composedStyles.countrySelectorDropdownArrow,
        dropdownStyleProps: {
          className: composedStyles.countrySelectorDropdown,
          listItemClassName: composedStyles.countrySelectorDropdownItem,
          listItemFlagClassName: styles.flag,
        },
        flagClassName: styles.flag,
      }}
      inputClassName={classNames(
        composedStyles.phoneInput,
        hasError && composedStyles.phoneInputError,
      )}
      forceDialCode={true}
      charAfterDialCode=" | "
      {...restProps}
    />
  )
})

const DefaultPhoneField = forwardRef(function DefaultPhoneField(
  { hasError, onChange, ...restProps }: InternalPhoneFieldProps,
  ref: ForwardedRef<PhoneInputRefType>,
): JSX.Element {
  const [showDialCode, setShowDialCode] = useState(false)

  function handlePhoneChange(phoneNumber: string) {
    setShowDialCode(phoneNumber.length > 0)
    onChange?.(phoneNumber)
  }

  return (
    <PhoneInput
      ref={ref}
      className={classNames(
        defaultStyles.phoneFieldContainer,
        hasError && defaultStyles.phoneFieldContainerError,
      )}
      countrySelectorStyleProps={{
        className: defaultStyles.countrySelector,
        buttonClassName: defaultStyles.countrySelectorButton,
        dropdownArrowClassName: defaultStyles.countrySelectorDropdownArrow,
        dropdownStyleProps: {
          className: defaultStyles.countrySelectorDropdown,
          listItemClassName: defaultStyles.countrySelectorDropdownItem,
          listItemFlagClassName: styles.flag,
        },
        flagClassName: styles.flag,
      }}
      inputClassName={defaultStyles.phoneInput}
      onChange={handlePhoneChange}
      disableDialCodePrefill={false}
      forceDialCode={showDialCode}
      showDisabledDialCodeAndPrefix={false}
      {...restProps}
    />
  )
})
