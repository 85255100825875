import { useFormContext } from 'react-hook-form'
import { useTrans } from 'src/ui/hooks/useTrans'
import { FormTextField } from 'src/ui/views/_components/FormTextField'
import { FormPhoneField } from 'src/ui/views/_components/FormPhoneField'
import { FormCheckbox } from 'src/ui/views/_components/FormCheckbox'
import { Translate } from 'src/ui/i18n/Translate'
import { Link, Text } from 'src/ui/components'
import { privacyPolicy } from 'src/ui/navigation/cmsLinks'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import styles from './CallMeFormContent.module.scss'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { isValidPhoneNumber } from 'libphonenumber-js'

export interface CallMeFormContentValues {
  name: string
  surname: string
  phone: string
  conditions: boolean
}

export function CallMeFormContent() {
  const { register } = useFormContext<CallMeFormContentValues>()
  const { trans } = useTrans(['common'])
  const languageConfig = useLanguageConfig()
  const defaultRequiredErrorMessage = trans('form-input_error_is-required')
  const defaultCountry = languageConfig.properties.phoneFieldLanguage
  const { getRawParams } = useApplicationRouter()
  return (
    <>
      <FormTextField
        {...register('name', { required: defaultRequiredErrorMessage })}
        placeholder={trans('call-me-form-content_name-placeholder')}
      />
      <FormTextField
        {...register('surname', { required: defaultRequiredErrorMessage })}
        placeholder={trans('call-me-form-content_surname-placeholder')}
      />
      <FormPhoneField<CallMeFormContentValues>
        name="phone"
        field={{
          required: defaultRequiredErrorMessage,
          minLength: { value: 5, message: defaultRequiredErrorMessage },
          validate: value => {
            const invalidPhoneMessage = trans(
              'call-me-form-content_phone-error-invalid',
            )

            if (typeof value !== 'string' || !isValidPhoneNumber(value)) {
              return invalidPhoneMessage
            }

            return true
          },
        }}
        placeholder={trans('call-me-form-content_phone-placeholder')}
        defaultCountry={defaultCountry}
      />
      <div className={styles.checkbox}>
        <FormCheckbox
          {...register('conditions', {
            required: {
              value: true,
              message: trans('call-me-form-content_conditions-error_is-true'),
            },
          })}
        >
          <Translate
            i18nKey="common:call-me-form-content_conditions"
            components={{
              regular: <Text fontStyle="s-300" />,
              bold: (
                <Link
                  fontStyle="m-500"
                  variant="secondary"
                  link={privacyPolicy(getRawParams(['store'])?.store)}
                >
                  <></>
                </Link>
              ),
            }}
          />
        </FormCheckbox>
      </div>
    </>
  )
}
