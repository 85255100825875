import type { ComponentProps } from 'react'
import { Button } from 'src/ui/components'
import { useFormContext } from 'react-hook-form'

type Props = ComponentProps<typeof Button>

export function FormSubmit({ children, ...props }: Props) {
  const { formState } = useFormContext()
  const { isSubmitting } = formState
  return (
    <Button
      {...props}
      type="submit"
      size={props.size}
      isDisabled={isSubmitting}
    >
      {children}
    </Button>
  )
}
