import { CSSProperties } from 'react'
import styles from './PageWidgets.module.scss'
import { usePageWidgetsConfiguration } from 'src/ui/contexts/PageWidgetsProvider'
import { GoToTopButton } from 'src/ui/components/molecules/GoToTopButton'
import { useScroll } from 'src/ui/hooks/useScroll'
import { classNames } from 'src/ui/utils/classnames'
import { useCallMeBack } from 'src/ui/hooks/useCallMeBack'
import { CallMeBackWidget } from 'src/ui/views/_components/CallMe/CallMeBackWidget'
import { useCallMeNow } from 'src/ui/hooks/useCallMeNow'
import { CallMeNowWidget } from 'src/ui/views/_components/CallMe/CallMeNowModal'

export const PageWidgets = () => {
  const { bottomMargin } = usePageWidgetsConfiguration()
  const { hasSignificantScroll, scrollPercent, goToTop } = useScroll()
  const {
    isWidgetVisible: showCallMeBack,
    modalType: typeCallMeBack,
    utagObject: analyticCallMeBack,
  } = useCallMeBack()
  const {
    isWidgetVisible: showCallMeNow,
    modalType: typeCallMeNow,
    utagObject: analyticCallMeNow,
  } = useCallMeNow()

  const styleProps = {
    '--b-pageWidgets-bottomMargin': `${bottomMargin}px`,
  } as CSSProperties

  return (
    <div className={styles.container} style={styleProps}>
      {showCallMeNow && (
        <CallMeNowWidget type={typeCallMeNow} analytic={analyticCallMeNow} />
      )}

      {showCallMeBack && (
        <div className={styles.item}>
          <CallMeBackWidget
            type={typeCallMeBack}
            analytic={analyticCallMeBack}
          />
        </div>
      )}

      <GoToTopButton
        className={classNames(
          styles.item,
          hasSignificantScroll ? undefined : styles.itemHidden,
        )}
        scrollPercent={scrollPercent}
        onClick={goToTop}
      />
    </div>
  )
}
