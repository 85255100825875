import { FC } from 'react'

interface Props {
  color: string
}

export const ArrowUpIcon: FC<Props> = ({ color }) => (
  <>
    <path
      d="M12.25 4L12.25 20.25"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 10.75L12.25 4L5.5 10.75"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default ArrowUpIcon
