export interface TabCommunication {
  send<T>(event: TabCommunicationEvent<T>): void
  on<T>(
    eventType: TabCommunicationEventType,
    handler: (data: T) => void,
  ): () => void
  off(
    eventType: TabCommunicationEventType,
    handler: (data: unknown) => void,
  ): void
  disconnect(): void
}

export type TabCommunicationEvent<T = unknown> = {
  type: TabCommunicationEventType
  data: T
}

export enum TabCommunicationEventType {
  USER_LOGGED_IN = 'USER_LOGGED_IN',
}

export type UserLoggedInEvent = {
  token: string
  currencyCode?: string
}
