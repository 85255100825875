import { CSSProperties, FC } from 'react'

import styles from './Loader.module.scss'

import { SpaceType } from 'src/ui/styles/settings'
import { useTrans } from 'src/ui/hooks/useTrans'

type LoaderType = 'gradient' | 'primary' | 'inverse' | 'secondary' | 'error'

interface Props {
  variant: LoaderType
  size?: SpaceType
}

export const Loader: FC<Props> = ({ variant, size = '4xl' }) => {
  const { trans } = useTrans(['common'])
  const gradient = getGradientColors(variant)

  const styleProps = {
    '--size': `var(--b-space-${size})`,
    '--from-color': `var(--b-color-loader-${gradient.from})`,
    '--to-color': `var(--b-color-loader-${gradient.to})`,
  } as CSSProperties

  return (
    <div
      className={styles.container}
      style={styleProps}
      data-testid="loader"
      aria-label={trans('loader_loading_aria-label')}
      role="alert"
    >
      <div className={styles.loader} />
    </div>
  )
}

const getGradientColors = (variant: Props['variant']) => {
  if (variant === 'gradient') {
    return { from: 'gradient-from', to: 'gradient-to' }
  }
  return { from: variant, to: variant }
}
