import {
  FocusEventHandler,
  FormEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
} from 'react'
import styles from './CheckboxField.module.scss'
import { Text } from '../Text'
import { classNames } from 'src/ui/utils/classnames'
import { Flex } from 'src/ui/styles/objects/Flex'

interface Props {
  children: ReactNode
  checked: boolean
  error?: { message?: string }
  onChange?: FormEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  name: string
  min?: string | number
  max?: string | number
  maxLength?: number
  minLength?: number
  pattern?: string
  required?: boolean
  disabled?: boolean
}

const CheckboxWithRef: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    children,
    checked,
    error,
    onChange,
    onBlur,
    name,
    min,
    max,
    maxLength,
    minLength,
    pattern,
    required,
    disabled,
    ...props
  },
  ref,
) => {
  return (
    <div>
      <Flex alignItems="flex-start" gap="xs">
        <input
          {...props}
          type="checkbox"
          ref={ref}
          id={name}
          className={classNames(styles.checkbox, error && styles.error)}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          min={min}
          max={max}
          maxLength={maxLength}
          minLength={minLength}
          pattern={pattern}
          required={required}
          disabled={disabled}
        />
        <label htmlFor={name} className={styles.label}>
          {children}
        </label>
      </Flex>

      {error && (
        <Text
          fontStyle="s-300"
          color="support-error-dark"
          className={styles.error}
        >
          {error.message}
        </Text>
      )}
    </div>
  )
}

export const CheckboxField = forwardRef(CheckboxWithRef)
