import type { CallMeFormContentValues } from 'src/ui/views/_components/CallMe/CallMeFormContent'
import { CallMeFormContent } from 'src/ui/views/_components/CallMe/CallMeFormContent'
import type { CallMeBackType } from 'src/ui/views/_components/CallMe'
import styles from './CallMeBackModal.module.scss'
import { createModal } from 'src/ui/hooks/useModal'
import { ModalV2 } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { CallMeModalHeader } from 'src/ui/views/_components/CallMe/CallMeModalHeader'
import { CallMeFormFooter } from 'src/ui/views/_components/CallMe/CallMeFormFooter'
import { Form } from 'src/ui/views/_components/Form'
import { FormRadioButtonGroup } from 'src/ui/views/_components/FormRadioButtonGroup'
import { FormSubmit } from 'src/ui/views/_components/FormSubmit'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useUser } from 'src/ui/contexts/UserContext'
import { useState } from 'react'

interface Props {
  type: CallMeBackType
  analytic: object
  onSubmit: (data: object) => void
}

enum Schedule {
  Morning = 'call-me-back-input-morning',
  Afternoon = 'call-me-back-input-afternoon',
}

interface CallMeBackFormValues extends CallMeFormContentValues {
  schedule: Schedule
}

export const CallMeBackModal = createModal(
  ({ type, analytic, onSubmit }: Props) => {
    const { trans } = useTrans(['common'])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const { user } = useUser()
    const { title, subtitle, button } = getTranslations()
    return (
      <ModalV2
        size="s-m"
        mobileStyle="centered"
        data-quantum-modal="CallMeBackModal"
        withButtons={false}
        customHeader={
          <CallMeModalHeader
            type={type}
            title={trans(title)}
            subtitle={trans(subtitle)}
          />
        }
      >
        <Form
          config={{
            onSubmit: handleSubmit,
            defaultValues: {
              name: user?.givenName,
              surname: user?.familyName,
              phone: user?.phoneNumber,
            },
          }}
        >
          <Flex direction="column" gap="m" alignItems="stretch">
            <FormRadioButtonGroup<CallMeBackFormValues, Schedule>
              name="schedule"
              field={{
                required: trans('form-input_error_is-required'),
              }}
              labelClassName="mb-m"
              label={trans('call-me-back-modal_schedule-v2')}
              options={[
                {
                  value: Schedule.Morning,
                  label: trans('call-me-back-modal_schedule-morning'),
                },
                {
                  value: Schedule.Afternoon,
                  label: trans('call-me-back-modal_schedule-afternoon'),
                },
              ]}
              optionsClassName={styles.scheduleOptions}
            />
            <CallMeFormContent />
          </Flex>
          <CallMeFormFooter>
            <FormSubmit
              type="submit"
              size={{
                mobile: 'fullWidth',
                laptop: 'large',
              }}
              isLoading={isSubmitting}
            >
              {trans(button)}
            </FormSubmit>
          </CallMeFormFooter>
        </Form>
      </ModalV2>
    )

    function getTranslations() {
      if (type === 'unique-exit-intent') {
        return {
          title: trans('call-me-back-modal_title-unique-exit-intent'),
          subtitle: trans('call-me-back-modal_subtitle-unique-exit-intent'),
          button: trans('call-me-back-modal_button-unique'),
        }
      }

      if (type === 'unique') {
        return {
          title: trans('call-me-back-modal_title-unique'),
          subtitle: trans('call-me-back-modal_subtitle-unique'),
          button: trans('call-me-back-modal_button-unique'),
        }
      }

      if (type === 'cancellation') {
        return {
          title: trans('call-me-back-modal_title'),
          subtitle: trans(
            'manage-reservation:management_cancellation_survey_option_best-price_cmn_title',
          ),
          button: trans('call-me-back-modal_button'),
        }
      }

      return {
        title: trans('call-me-back-modal_title'),
        subtitle: trans('call-me-back-modal_subtitle'),
        button: trans('call-me-back-modal_button'),
      }
    }

    async function handleSubmit(data: CallMeBackFormValues) {
      setIsSubmitting(true)
      const callMeBackData = {
        ...analytic,
        isoc_first_name: data.name,
        isoc_last_name: data.surname,
        user_phone: data.phone,
        isoc_call_time: data.schedule,
      }

      await onSubmit?.(callMeBackData)
      setIsSubmitting(false)
    }
  },
)
