import { asFunction, asValue, asClass } from 'awilix'
import { container } from './index'
import {
  ReservationApiClient,
  reservationApiClientAxiosInstance,
} from 'src/core/Shared/infrastructure/reservationApiClient'
import { sessionStorageCartRepository } from 'src/core/Cart/infrastructure/Cart.sessionStorage.repository'
import { sessionStorageClient } from 'src/core/Shared/infrastructure/sessionStorageClient'
import { apiReservationRepository } from 'src/core/Reservation/infrastructure/Reservation.api.repository'
import { stepperManager } from 'src/core/Shared/infrastructure/stepperManager'
import { eventsManager } from 'src/core/Shared/infrastructure/eventsManager'
import { reservationStorageRepository } from 'src/core/Reservation/infrastructure/Reservation.storage.repository'
import { localesManager } from 'src/core/Shared/infrastructure/locales/localesManager'
import { apiCurrencyRepository } from 'src/core/User/infrastructure/Currency.api.repository'
import { apiMarketRepository } from 'src/core/User/infrastructure/Market.api.repository'
import { analytics } from 'src/core/Shared/infrastructure/analytics'

import { cryptography } from 'src/core/Shared/infrastructure/cryptography'
import { apiAuthRepository } from 'src/core/User/infrastructure/Auth.api.repository'
import { apiCountryRepository } from 'src/core/User/infrastructure/Country.api.repository'
import { logger } from 'src/core/Shared/infrastructure/errors/logger'
import { consoleLogger } from 'src/core/Shared/infrastructure/errors/consoleLogger'
import { sentry } from 'src/core/Shared/infrastructure/errors/sentry'
import { envManager } from 'src/core/Shared/infrastructure/envManager'
import {
  UserConfigApiClient,
  userConfigApiClientAxiosInstance,
} from 'src/core/Shared/infrastructure/userConfigApiClient'
import {
  AvailabilityApiClient,
  availabilityApiClientAxiosInstance,
} from 'src/core/Shared/infrastructure/availabilityApiClient'
import {
  AuthApiClient,
  authApiClientAxiosInstance,
} from 'src/core/Shared/infrastructure/authApiClient'
import {
  CmsApiClient,
  cmsApiClientAxiosInstance,
} from 'src/core/Shared/infrastructure/cmsApiClient'
import {
  HotelInfoApiClient,
  hotelInfoApiClientAxiosInstance,
} from 'src/core/Shared/infrastructure/hotelInfoApiClient'
import { windowEvents } from 'src/core/Shared/infrastructure/windowEvents'
import { transformToMemberReservationAfterLogin } from 'src/core/Reservation/usecases/transformToMemberReservationAfterLogin'
import { getHotelById } from 'src/core/Hotel/usecases/getHotelById'
import { getCurrencies } from 'src/core/User/usecases/getCurrencies'
import { changeLanguage } from 'src/core/User/usecases/changeLanguage'
import { apiHotelRepository } from 'src/core/Hotel/infrastructure/Hotel.api.repository'
import { getUser } from 'src/core/User/usecases/getUser'
import { deleteCart } from 'src/core/Cart/usecases/deleteCart'
import { pixelsManager } from 'src/core/Shared/infrastructure/pixelsManager'
import { getCampaignForCoupon } from 'src/core/Hotel/usecases/getCampaignForCoupon'
import { ratePairStorageRepository } from 'src/core/Shared/infrastructure/RatePair.repository'
import { getCheckInAndCheckOutTime } from 'src/core/Hotel/usecases/getCheckInAndCheckOutTime'
import { apiStoreRepository } from 'src/core/User/infrastructure/Store.api.repository'
import {
  ContactApiClient,
  contactApiClientAxiosInstance,
} from 'src/core/Shared/infrastructure/contactApiClient'
import { apiContactRepository } from 'src/core/User/infrastructure/Contact.api.repository'
import { contactStorageRepository } from 'src/core/User/infrastructure/Contact.storage.repository'
import { createBroadcastTabCommunication } from '../infrastructure/BroadcastTabCommunication'
import { roomsInventoryRepository } from 'src/core/Shared/infrastructure/roomsInventory.repository'
import { applicationExperienceStorageRepository } from '../infrastructure/ApplicationExperience.storage.repository'

const modules = {
  reservationApiClientAxiosInstance: asValue(reservationApiClientAxiosInstance),
  userConfigApiClientAxiosInstance: asValue(userConfigApiClientAxiosInstance),
  availabilityApiClientAxiosInstance: asValue(
    availabilityApiClientAxiosInstance,
  ),
  contactApiClientAxiosInstance: asValue(contactApiClientAxiosInstance),

  windowEvents: asValue(windowEvents),
  stepperManager: asFunction(stepperManager),
  eventsManager: asValue(eventsManager),
  pixelsManager: asValue(pixelsManager),
  cryptography: asValue(cryptography),
  localesManager: asValue(localesManager),
  marketRepository: asFunction(apiMarketRepository),
  storeRepository: asFunction(apiStoreRepository),

  consoleLogger: asValue(consoleLogger),
  sentry: asValue(sentry),
  logger: asFunction(logger),

  authApiClientAxiosInstance: asValue(authApiClientAxiosInstance),
  cmsApiClientAxiosInstance: asValue(cmsApiClientAxiosInstance),
  hotelInfoApiClientAxiosInstance: asValue(hotelInfoApiClientAxiosInstance),
  envManager: asValue(envManager),
  analytics: asValue(analytics),
  cmsApiClient: asClass(CmsApiClient),
  hotelInfoApiClient: asClass(HotelInfoApiClient),
  availabilityApiClient: asClass(AvailabilityApiClient),
  reservationApiClient: asClass(ReservationApiClient),
  contactApiClient: asClass(ContactApiClient),
  authApiClient: asClass(AuthApiClient),
  userConfigApiClient: asClass(UserConfigApiClient),

  reservationStorageRepository: asFunction(reservationStorageRepository),
  ratePairStorageRepository: asFunction(ratePairStorageRepository),
  roomsInventoryRepository: asFunction(roomsInventoryRepository),
  contactStorageRepository: asFunction(contactStorageRepository),

  sessionStorageClient: asValue(sessionStorageClient),
  cartRepository: asFunction(sessionStorageCartRepository),
  reservationRepository: asFunction(apiReservationRepository),
  currencyRepository: asFunction(apiCurrencyRepository),
  countryRepository: asFunction(apiCountryRepository),
  authRepository: asFunction(apiAuthRepository),
  hotelRepository: asFunction(apiHotelRepository),
  contactRepository: asFunction(apiContactRepository),
  applicationExperienceStorageRepository: asFunction(applicationExperienceStorageRepository),

  transformToMemberReservationAfterLogin: asFunction(
    transformToMemberReservationAfterLogin,
  ),
  getHotelById: asFunction(getHotelById),
  getCurrencies: asFunction(getCurrencies),
  changeLanguage: asFunction(changeLanguage),
  getUser: asFunction(getUser),
  deleteCart: asFunction(deleteCart),

  getCampaign: asFunction(getCampaignForCoupon),
  getCheckInAndCheckOutTime: asFunction(getCheckInAndCheckOutTime),

  tabCommunication: asFunction(createBroadcastTabCommunication)
    .inject(() => ({ channelName: 'tab-communication' }))
    .singleton()
    .disposer(tabCommunication => tabCommunication.disconnect()),
}

export const registerModules = () => {
  container.register(modules)
}

export default modules
