import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react'

interface PageWidgetsContextData {
  readonly bottomMargin: number
  addFooterElement: (element: HTMLElement | null) => () => void
}

const PageWidgetsContext = createContext<PageWidgetsContextData>({
  bottomMargin: 0,
  addFooterElement: () => () => {},
})

interface Props {
  children: ReactNode
}

export const PageWidgetsProvider: FC<Props> = ({ children }) => {
  const [bottomMargin, setBottomMargin] = useState(0)
  const footerElements = useRef<Set<HTMLElement>>(new Set())

  const updateBottomMargin = useCallback(() => {
    const maxHeight = Array.from(footerElements.current).reduce(
      (max, element) => Math.max(max, element.offsetHeight),
      0,
    )
    setBottomMargin(maxHeight)
  }, [])

  const addElementBottonMargin = useCallback(
    (element: HTMLElement) => {
      footerElements.current.add(element)
      updateBottomMargin()
    },
    [updateBottomMargin],
  )

  const removeElementBottonMargin = useCallback(
    (element: HTMLElement) => {
      if (footerElements.current.delete(element)) {
        updateBottomMargin()
      }
    },
    [updateBottomMargin],
  )

  const addFooterElement = useCallback(
    (element: HTMLElement | null) => {
      if (!element) {
        return () => {}
      }

      let intersectionObserver: IntersectionObserver | undefined = undefined

      const handleElementIntersection: IntersectionObserverCallback = ([
        entry,
      ]) => {
        if (entry.isIntersecting) {
          addElementBottonMargin(element)
        } else {
          removeElementBottonMargin(element)
        }
      }

      const handleElementResize: ResizeObserverCallback = () => {
        intersectionObserver?.disconnect()
        const topPadding =
          document.documentElement.clientHeight - element.offsetHeight
        intersectionObserver = new IntersectionObserver(
          handleElementIntersection,
          {
            rootMargin: `-${topPadding}px 0px 0px 0px`,
            threshold: 0.75,
          },
        )
        intersectionObserver.observe(element)
      }

      const resizeObserver = new ResizeObserver(handleElementResize)
      resizeObserver.observe(element)
      resizeObserver.observe(document.documentElement)

      return () => {
        resizeObserver.disconnect()
        intersectionObserver?.disconnect()
        removeElementBottonMargin(element)
      }
    },
    [addElementBottonMargin, removeElementBottonMargin],
  )

  return (
    <PageWidgetsContext.Provider value={{ bottomMargin, addFooterElement }}>
      {children}
    </PageWidgetsContext.Provider>
  )
}

type UsePageWidgetsConfiguratorProps = {
  isElementVisible?: boolean
}

export const usePageWidgetsConfigurator = ({
  isElementVisible = true,
}: UsePageWidgetsConfiguratorProps = {}) => {
  const { addFooterElement } = useContext(PageWidgetsContext)
  const [footerElement, setFooterElement] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const element = isElementVisible ? footerElement : null
    const removeFooterElement = addFooterElement(element)
    return () => {
      removeFooterElement()
    }
  }, [isElementVisible, footerElement, addFooterElement])

  return {
    setFooterElement,
  }
}

export const usePageWidgetsConfiguration = () => {
  const { bottomMargin } = useContext(PageWidgetsContext)

  return {
    bottomMargin,
  }
}
