import { FC } from 'react'
import MarkdownComponent, { Options, Components } from 'react-markdown'
import remarkGfm from 'remark-gfm'
import styles from './Markdown.module.scss'

const remarkPlugins: Options['remarkPlugins'] = [remarkGfm]

interface Props {
  asPlainText?: boolean
  content: string | null | undefined
}

export const Markdown: FC<Props> = ({ asPlainText, content }) => {
  if (asPlainText) {
    return (
      <MarkdownComponent
        allowedElements={[]}
        unwrapDisallowed={true}
        remarkPlugins={remarkPlugins}
      >
        {content}
      </MarkdownComponent>
    )
  }

  return (
    <MarkdownComponent remarkPlugins={remarkPlugins} components={components}>
      {content}
    </MarkdownComponent>
  )
}

const components: Components = {
  h1: ({ children }) => <h1 className={styles.header1}>{children}</h1>,
  h2: ({ children }) => <h2 className={styles.header2}>{children}</h2>,
  h3: ({ children }) => <h3 className={styles.header3}>{children}</h3>,
  h4: ({ children }) => <h4 className={styles.header4}>{children}</h4>,
  h5: ({ children }) => <h5 className={styles.header5}>{children}</h5>,
  h6: ({ children }) => <h6 className={styles.header6}>{children}</h6>,
  p: ({ children }) => <p className={styles.paragraph}>{children}</p>,
  strong: ({ children }) => (
    <strong className={styles.strong}>{children}</strong>
  ),
  em: ({ children }) => <em className={styles.italic}>{children}</em>,
  ul: ({ children }) => <ul className={styles.list}>{children}</ul>,
  ol: ({ children }) => <ol className={styles.list}>{children}</ol>,
  li: ({ children }) => <li className={styles.listItem}>{children}</li>,
  blockquote: ({ children }) => (
    <blockquote className={styles.blockquote}>{children}</blockquote>
  ),
  table: ({ children }) => <table className={styles.table}>{children}</table>,
  th: ({ children }) => <th className={styles.tableHeader}>{children}</th>,
  td: ({ children }) => <td className={styles.tableCell}>{children}</td>,
  a: ({ children, href }) => (
    <a href={href} target="_blank">
      {children}
    </a>
  ),
}
