import { Hotel } from 'src/core/Hotel/domain/Hotel.model'
import { HotelRepository } from 'src/core/Hotel/domain/Hotel.repository'
import { Analytics, ProcessType } from 'src/core/Shared/domain/Analytics'

export const getHotelById =
  ({ hotelRepository, analytics }: Dependencies) =>
  (
    hotelId: string,
    language: string,
    hasChildren: boolean,
    process: ProcessType,
    skipAnalytics: boolean = false,
  ) =>
  async (): Promise<Hotel> => {
    const hotel = await hotelRepository.getById(hotelId, language, hasChildren)
    if (!skipAnalytics) {
      analytics.requests.hotel(hotel, process)
    }

    return hotel
  }

interface Dependencies {
  hotelRepository: HotelRepository
  analytics: Analytics
}
