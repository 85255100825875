import { AnalyticsActionsExtrasContinueWithoutExtras } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const continueWithoutExtras: AnalyticsActionsExtrasContinueWithoutExtras =
  process => {
    const linkParams = {
      ga4_event_name: 'step2_button_no_extras',
      event: 'step2_button_no_extras',
      event_category: 'extras',
      event_action: 'continue_with_no_extras',
      event_label: 'user goes to step2 without extras',

      booking_extras: undefined,
      booking_extras_id: undefined,
      booking_extras_units: undefined,
      booking_extras_price: 0,
      booking_extras_total_price: 0,
      booking_extras_currency: undefined,
      process,
    }
    container
      .resolve('logger')
      .info('Analytics/links/actions/extras/continueWithoutExtras', {
        linkParams,
        utag_data: window.utag_data,
      })
    window.utag.link(linkParams)
  }
