import { createContext, useEffect, useRef, useState } from 'react'
import {
  fetchTokens,
  redirectToLogin,
  redirectToLogout,
  validateState,
} from './authentication'
import useLocalStorage from './Hooks'
import {
  IAuthContext,
  IAuthProvider,
  TInternalConfig,
  TTokenData,
  TTokenResponse,
} from './Types'
import { validateAuthConfig } from './validateAuthConfig'
import { jwtUtils } from 'lib/oauth2-pkce/decodeJWT'
import { analytics } from 'src/core/Shared/infrastructure/analytics'
import { container } from 'src/core/Shared/_di'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  TabCommunicationEventType,
  UserLoggedInEvent,
} from 'src/core/Shared/domain/TabCommunication'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { Semaphore } from 'src/core/Shared/infrastructure/wrappers/Semaphore'

export const AuthContext = createContext<IAuthContext>({
  token: '',
  login: () => null,
  logOut: () => null,
  clearError: () => null,
  error: null,
  loginInProgress: false,
})

export const AuthProvider = ({ authConfig, children }: IAuthProvider) => {
  const [token, setToken] = useLocalStorage<string>('ROCP_token', '')
  const [idToken, setIdToken] = useLocalStorage<string | undefined>(
    'ROCP_idToken',
    undefined,
  )
  const [loginInProgress, setLoginInProgress] = useLocalStorage<boolean>(
    'ROCP_loginInProgress',
    false,
  )
  const [tokenData, setTokenData] = useState<TTokenData | undefined>()
  const [idTokenData, setIdTokenData] = useState<TTokenData | undefined>()
  const [error, setError] = useState<string | null>(null)
  const loginSemaphore = useRef(new Semaphore('login'))

  const tabCommunication = container.resolve('tabCommunication')
  const { queryUtils } = useApplicationRouter()

  // Set default values for internal config object
  const {
    autoLogin = true,
    clearURL = true,
    decodeToken = true,
    scope = '',
    preLogin = () => null,
    postLogin = () => null,
    onError = () => null,
  } = authConfig

  const config: TInternalConfig = {
    ...authConfig,
    autoLogin: autoLogin,
    clearURL: clearURL,
    decodeToken: decodeToken,
    scope: scope,
    preLogin: preLogin,
    postLogin: postLogin,
  }

  validateAuthConfig(config)

  function clearStorage() {
    setToken('')
    setIdToken(undefined)
    setTokenData(undefined)
    setIdTokenData(undefined)
    setLoginInProgress(false)
  }

  function logOut() {
    analytics.actions.shared.logout()
    clearStorage()
    if (config?.redirectAfterLogoutEndpoint)
      /**
       * Modificado para que se ajuste a la casuística de la aplicación
       */
      redirectToLogout(config)
  }

  function login(state?: string) {
    loginSemaphore.current.execute(() => {
      clearStorage()
      setLoginInProgress(true)
      if (typeof state !== 'string') {
        console.warn(
          `Passed login state must be of type 'string'. Received '${state}'. Ignoring value...`,
        )
        redirectToLogin(config)
        return
      }
      redirectToLogin(config, state)
    })
  }

  function handleTokenResponse(response: TTokenResponse) {
    setToken(response.access_token)
    setIdToken(response.id_token)
    try {
      if (config.decodeToken)
        setTokenData(jwtUtils.decodeJWT(response.access_token))
      if (config.decodeToken && response.id_token)
        setIdTokenData(jwtUtils.decodeJWT(response.id_token))
    } catch (e) {
      setError((e as Error).message)
      onError()
    }
  }

  // This ref is used to make sure the 'fetchTokens' call is only made once.
  // Multiple calls with the same code will, and should, return an error from the API
  // See: https://beta.reactjs.org/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
  const didFetchTokens = useRef(false)

  // Runs once on page load
  useEffect(() => {
    if (loginInProgress) {
      // The client has been redirected back from the Auth endpoint with an auth code
      const urlParams = new URLSearchParams(window.location.search)
      if (!urlParams.get('code')) {
        // This should not happen. There should be a 'code' parameter in the url by now..."
        const error_description =
          urlParams.get('error_description') ||
          'Bad authorization state. Refreshing the page might solve the issue.'
        console.error(error_description)
        setError(error_description)
        onError()
        /**
         * Se ha modificado el comportamiento de la librería para que en lugar de hacer un logout completo haga la limpieza del storage
         */
        clearStorage()
      } else if (!didFetchTokens.current) {
        didFetchTokens.current = true
        try {
          validateState(urlParams)
        } catch (e: unknown) {
          console.error(e)
          setError((e as Error).message)
          onError()
        }
        // Request token from auth server with the auth code
        fetchTokens(config)
          .then((tokens: TTokenResponse) => {
            handleTokenResponse(tokens)
            // Call any postLogin function in authConfig
            if (config?.postLogin) config.postLogin(tokens.access_token)
            if (isDefined(tabCommunication)) {
              const currencyCode = queryUtils.getCurrencyParam()
              tabCommunication.send<UserLoggedInEvent>({
                type: TabCommunicationEventType.USER_LOGGED_IN,
                data: { token: tokens.access_token, currencyCode },
              })
            }
          })
          .catch((error: Error) => {
            console.error(error)
            setError(error.message)
            onError()
          })
          .finally(() => {
            if (config.clearURL) {
              // Clear ugly url params
              window.history.replaceState(null, '', window.location.pathname)
            }
            loginSemaphore.current.unlock()
            setLoginInProgress(false)
          })
      }
    } else if (!token) {
      // First page visit
      if (config.autoLogin) login(authConfig.state)
    } else {
      if (decodeToken) {
        try {
          setTokenData(jwtUtils.decodeJWT(token))
          if (idToken) setIdTokenData(jwtUtils.decodeJWT(idToken))
        } catch (e) {
          setError((e as Error).message)
          onError()
        }
      }
    }
  }, []) // eslint-disable-line

  function clearError() {
    setError(null)
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        tokenData,
        idToken,
        idTokenData,
        login,
        logOut,
        error,
        loginInProgress,
        clearError,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
