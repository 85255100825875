import { useEffect, useState, useCallback } from 'react'
import { useMedia } from 'src/ui/hooks/useMedia'

const MOBILE_SIGNIFICANT_SCROLL = 300
const DESKTOP_SIGNIFICANT_SCROLL = 600

export const useScroll = () => {
  const [hasSignificantScroll, setHasSignificantScroll] = useState(false)
  const [scrollPercent, setScrollPercent] = useState(0)
  const { isMobileOrTablet } = useMedia()

  const handleScroll = useCallback(() => {
    const { scrollHeight, clientHeight, scrollTop } = document.documentElement

    const significantScroll = isMobileOrTablet
      ? MOBILE_SIGNIFICANT_SCROLL
      : DESKTOP_SIGNIFICANT_SCROLL
    setHasSignificantScroll(scrollTop >= significantScroll)

    const maxScrollTop = scrollHeight - clientHeight
    const percent = (scrollTop / maxScrollTop) * 100
    setScrollPercent(percent)
  }, [setHasSignificantScroll, setScrollPercent, isMobileOrTablet])

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const goToTop = useCallback(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  return { hasSignificantScroll, scrollPercent, goToTop }
}
