import { useContext } from 'react'

import { MediaContext } from 'src/ui/contexts/MediaContext'

export const useMedia = () => {
  const media = useContext(MediaContext)

  const isMobileOrTablet = media === 'mobile' || media === 'tablet'

  const isMobile = media === 'mobile'

  return { media, isMobileOrTablet, isMobile }
}
