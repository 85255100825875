import { FC, ReactNode, useEffect, useState } from 'react'
import { usePageNotFound } from 'src/ui/hooks/usePageNotFound'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useRequiredQueryParams } from 'src/ui/hooks/useRequiredQueryParams'
import { CookiesProvider } from 'react-cookie'
import { CurrencyProvider } from 'src/ui/contexts/CurrencyContext'
import { AuthProvider } from 'src/ui/contexts/AuthProvider'
import { AuthProvider as Oauth2PKCECustomProvider } from 'lib/oauth2-pkce'
import { LanguageConfigProvider } from 'src/ui/contexts/LanguageConfigContext'
import { MediaProvider } from 'src/ui/contexts/MediaContext'
import { CountryProvider } from 'src/ui/contexts/CountryContext'
import { MarketProvider } from 'src/ui/contexts/MarketContext'
import { ModalsProvider } from 'src/ui/contexts/ModalsProvider'
import { useMaxModalHeight } from 'src/ui/hooks/useMaxModalHeight'
import packageJson from '../../../../package.json'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { FullScreenLoader } from 'src/ui/components/molecules/FullScreenLoader'
import { useCookies } from 'src/ui/hooks/useCookies'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'
import { PageWidgetsProvider } from 'src/ui/contexts/PageWidgetsProvider'
import { PageWidgets } from 'src/ui/views/_components/PageWidgets'

const version = packageJson.version

export const fetchCache = 'default-cache'

interface ChildrenProps {
  children: ReactNode
}

export const Launcher: FC<ChildrenProps> = ({ children }) => {
  const [isLanguageLoading, setIsLanguageLoading] = useState(true)
  const [isLoginLoading, setIsLoginLoading] = useState(true)
  const [isCurrencyLoading, setIsCurrencyLoading] = useState(true)
  const { isPageNotFound } = usePageNotFound()
  const { registerPreviousRoutes } = useApplicationRouter()
  const { setCookie } = useCookies()

  useRequiredQueryParams(!isLoginLoading)
  useApplicationVersion()
  useUtagChecker()
  registerPreviousRoutes()
  useMaxModalHeight()

  useEffect(() => {
    if (isFeatureFlagEnabled('FF_POC_SET_COOKIES')) {
      setCookie('ROCP_idToken', 'test_access_token', {
        path: '/',
        domain: 'barcelo.com',
        secure: true,
        sameSite: 'none',
        maxAge: 3600,
      })
      setCookie('ROCP_token', 'test_refresh_token', {
        path: '/',
        domain: 'barcelo.com',
        secure: true,
        sameSite: 'none',
        maxAge: 3600,
      })
    }
  }, [])

  return (
    <CookiesProvider>
      <CurrencyProvider setIsLoading={setIsCurrencyLoading}>
        <AuthProvider
          setIsLoading={setIsLoginLoading}
          OauthPKCEProvider={Oauth2PKCECustomProvider}
        >
          <LanguageConfigProvider setIsLoading={setIsLanguageLoading}>
            <MediaProvider>
              <CountryProvider>
                <MarketProvider>
                  <ModalsProvider>
                    <PageWidgetsProvider>
                      {!isPageNotFound &&
                      (isLoginLoading ||
                        isLanguageLoading ||
                        isCurrencyLoading) ? (
                        <FullScreenLoader />
                      ) : (
                        <>
                          {children}
                          <PageWidgets />
                        </>
                      )}
                    </PageWidgetsProvider>
                  </ModalsProvider>
                </MarketProvider>
              </CountryProvider>
            </MediaProvider>
          </LanguageConfigProvider>
        </AuthProvider>
      </CurrencyProvider>
    </CookiesProvider>
  )
}

const useApplicationVersion = () => {
  useEffect(() => {
    console.log(`v${version}`)
  }, [])

  return null
}

const useUtagChecker = () => {
  useEffect(() => {
    if (
      isUndefined(window.utag) ||
      isUndefined(window.utag.view) ||
      isUndefined(window.utag.link)
    ) {
      console.log('window.utag is not defined')
    } else {
      console.log('window.utag is loaded')
    }
  })

  return null
}
