import {
  EnvManager,
  Env,
  JoeDispenzaEvents,
} from 'src/core/Shared/domain/EnvManager'

export const envManager: EnvManager = {
  getEnv: () => process.env.NEXT_PUBLIC_ENV as Env,
  isProduction: () => process.env.NEXT_PUBLIC_ENV === 'production',
  getBaseImagesUrl: () => process.env.NEXT_PUBLIC_IMAGES_BASE_URL as string,
  getLoggerConfig: () =>
    JSON.parse(process.env.NEXT_PUBLIC_LOGGER_CONFIG as string),
  getCookiesConfig: () =>
    JSON.parse(process.env.NEXT_PUBLIC_COOKIES_CONFIG as string),
  isApiMockingEnabled: () => process.env.NEXT_PUBLIC_API_MOCKING === 'enabled',
  getAuthConfig: () =>
    JSON.parse(process.env.NEXT_PUBLIC_AUTH_CONFIG as string),
  hasToAppendScripts: () => {
    return (
      process.env.NEXT_PUBLIC_ENV !== 'mock' &&
      process.env.NEXT_PUBLIC_DISABLE_SCRIPTS !== 'true'
    )
  },
  getJoeDispenzaEvents: () => {
    const joeDispenzaEvents = JSON.parse(
      process.env.NEXT_PUBLIC_JOE_DISPENZA_EVENTS || '{}',
    ) as JoeDispenzaEvents

    return joeDispenzaEvents
  },
  getGroupCodesWithoutBarceloExperience: () => {
    const groupCodesNotBarceloExperience = JSON.parse(
      process.env.NEXT_PUBLIC_GROUP_CODES_NOT_BARCELO_EXPERIENCE || '[]',
    ) as string[]

    return groupCodesNotBarceloExperience
  },
}
