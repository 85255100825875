import { FC, ReactNode } from 'react'
import { Icon } from 'src/ui/components/atoms/Icon'
import { Text } from 'src/ui/components/atoms/Text'
import CloseIcon from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import styles from './ModalHeader.module.scss'
import { useTrans } from 'src/ui/hooks/useTrans'
import {
  isDefined,
  isNotEmptyString,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface Props {
  title?: string
  subtitle?: string
  isClosable: boolean
  onClose: () => void
  customHeader?: ReactNode
}

export const ModalHeader: FC<Props> = ({
  title,
  subtitle,
  isClosable,
  onClose,
  customHeader,
}) => {
  const { trans } = useTrans(['common'])

  const hasTitle = isDefined(title) && isNotEmptyString(title)
  const hasSubtitle = isDefined(subtitle) && isNotEmptyString(subtitle)
  const hasCustomHeader = isDefined(customHeader)
  return (
    <div className={styles.container}>
      <div className="w-full">
        {hasCustomHeader ? (
          customHeader
        ) : (
          <>
            {hasTitle && (
              <Text fontStyle={{ mobile: 'xl-700', laptop: '2xl-700' }} as="h1">
                {title}
              </Text>
            )}
            {hasSubtitle && (
              <Text
                fontStyle={{ mobile: 'ml-700', laptop: 'l-700' }}
                className="mt-s"
                as="h2"
              >
                {subtitle}
              </Text>
            )}
          </>
        )}
      </div>
      {isClosable && (
        <button
          className={styles.exitButton}
          onClick={onClose}
          aria-label={trans('modal_close-button_aria-label')}
        >
          <Icon size="l" icon={CloseIcon} className="cursor-pointer" />
        </button>
      )}
    </div>
  )
}
