import { FC, ReactNode } from 'react'
import styles from './ModalV2.module.scss'
import { classNames } from 'src/ui/utils/classnames'
import { useEscapeOnClose } from 'src/ui/hooks/useEscapeOnClose'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useModal } from 'src/ui/hooks/useModal'
import ReactModal from 'react-modal'

import { ModalFooter } from './ModalFooter'
import { ModalHeader } from './ModalHeader'
import { ModalContentV2 } from './ModalContent'
import { useLockBodyScroll } from 'src/ui/hooks/useLockBodyScroll'

type ModalSize = 's-m' | 'l' | 'xl'
type MobileStyle = 'bottom' | 'fullscreen' | 'centered'

interface Props {
  children: ReactNode
  ariaLabel?: string
  className?: string
  contentClassName?: string
  size: ModalSize
  mobileStyle?: MobileStyle
  onCloseIconClick?: () => void
  onClose?: () => void
  'data-testid'?: string
  'data-quantum-modal'?: string
  title?: string
  subtitle?: string
  customHeader?: ReactNode
  isClosable?: boolean
  withButtons?: boolean
  primaryButtonLabel?: string
  secondaryButtonLabel?: string
  terciaryButtonLabel?: string
  onPrimaryButtonClick?: () => void | Promise<void>
  onSecondaryButtonClick?: () => void | Promise<void>
  onTerciaryButtonClick?: () => void | Promise<void>
  hasToLockBodyScroll?: boolean
}

export const ModalV2: FC<Props> = ({
  children,
  ariaLabel,
  className,
  contentClassName,
  size,
  mobileStyle = 'bottom',
  onCloseIconClick,
  onClose,
  'data-testid': testId,
  'data-quantum-modal': dataQuantumModal,
  title,
  subtitle,
  customHeader,
  withButtons = true,
  isClosable = true,
  primaryButtonLabel,
  secondaryButtonLabel,
  terciaryButtonLabel,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onTerciaryButtonClick,
  hasToLockBodyScroll = true,
}) => {
  const { visibleModal, hideModal, removeModal } = useModal()
  const { lockBodyScroll, releaseBodyScroll } = useLockBodyScroll()

  const close = () => {
    hideModal()
    onClose?.()
  }

  const handleClose = () => {
    close()

    if (isDefined(onCloseIconClick)) {
      onCloseIconClick()
    }
  }

  useEscapeOnClose({ onClose: close })

  // It is important for users of screenreaders that other page content be hidden while the modal is open
  // https://reactcommunity.org/react-modal/accessibility/
  if (
    process.env.NODE_ENV !== 'test' &&
    document.getElementById('__next') !== null
  ) {
    ReactModal.setAppElement('#__next')
  }

  const isMobileFullscreen = mobileStyle === 'fullscreen'
  const isMobileCentered = mobileStyle === 'centered'

  return (
    <ReactModal
      isOpen={visibleModal}
      style={{
        overlay: {
          position: 'unset',
          inset: 'unset',
          backgroundColor: 'transparent',
        },
        content: {
          padding: 'unset',
          border: 'unset',

          // Posicionamiento de la modal frente al resto
          zIndex: '10000',

          // en iOS 14, el color de fondo que pone el componente hace que se
          // vea mal, sin color de fondo perfecto
          backgroundColor: 'unset',
        },
      }}
      onRequestClose={() => handleClose()}
      onAfterOpen={() => {
        hasToLockBodyScroll && lockBodyScroll()
      }}
      onAfterClose={() => {
        hasToLockBodyScroll && releaseBodyScroll()
        removeModal()
      }}
    >
      <div
        className={classNames(
          styles.container,
          isMobileFullscreen && styles.container_isMobileFullscreen,
        )}
        role="dialog"
        aria-label={ariaLabel}
        data-testid={testId}
        data-quantum-modal={dataQuantumModal}
      >
        <div
          className={classNames(
            styles.wrapper,
            isMobileFullscreen && styles.wrapper_isMobileFullscreen,
          )}
        >
          <div
            className={classNames(
              styles.modal,
              styles[`modal_${size}`],
              isMobileFullscreen && styles.modal_isMobileFullscreen,
              isMobileCentered && styles.modal_isMobileCentered,
              isDefined(className) && className,
            )}
            onClick={e => e.stopPropagation()}
          >
            <ModalHeader
              title={title}
              subtitle={subtitle}
              customHeader={customHeader}
              isClosable={isClosable}
              onClose={() => handleClose()}
            />

            <ModalContentV2
              className={contentClassName}
              isMobileFullscreen={isMobileFullscreen}
            >
              {children}
            </ModalContentV2>

            {withButtons && (
              <ModalFooter
                primaryButtonLabel={primaryButtonLabel}
                onPrimaryButtonClick={onPrimaryButtonClick}
                secondaryButtonLabel={secondaryButtonLabel}
                onSecondaryButtonClick={onSecondaryButtonClick}
                terciaryButtonLabel={terciaryButtonLabel}
                onTerciaryButtonClick={onTerciaryButtonClick}
              />
            )}
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
