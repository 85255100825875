import {
  AnalyticsActionsExtrasOpenModal,
  ProcessType,
} from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const openModal: AnalyticsActionsExtrasOpenModal = (
  extra,
  hasAvailability,
  process: ProcessType,
) => {
  const linkParams = {
    ga4_event_name: 'click_card_extras',
    event: 'click_card_extras',
    event_category: 'extras',
    event_action: 'click on extras card',
    event_label: 'user clicked on card',
    extra_dispo: hasAvailability,
    extra_name: extra.name,
    extra_id: extra.id,
    process,
  }
  container.resolve('logger').info('Analytics/links/actions/extras/openModal', {
    linkParams,
    utag_data: window.utag_data,
  })
  window.utag.link(linkParams)
}
