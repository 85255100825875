import { FC, useState } from 'react'
import { Divider } from 'src/ui/components/atoms/Divider'
import styles from './ModalFooter.module.scss'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Button } from 'src/ui/components/atoms/Button'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'

interface Props {
  primaryButtonLabel?: string
  secondaryButtonLabel?: string
  terciaryButtonLabel?: string
  onPrimaryButtonClick?: () => void | Promise<void>
  onSecondaryButtonClick?: () => void | Promise<void>
  onTerciaryButtonClick?: () => void | Promise<void>
}

export const ModalFooter: FC<Props> = ({
  primaryButtonLabel,
  secondaryButtonLabel,
  terciaryButtonLabel,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onTerciaryButtonClick,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const hasPrimaryButton =
    isDefined(primaryButtonLabel) && isDefined(onPrimaryButtonClick)
  const hasSecondaryButton =
    isDefined(secondaryButtonLabel) && isDefined(onSecondaryButtonClick)
  const hasTerciaryButton =
    isDefined(terciaryButtonLabel) && isDefined(onTerciaryButtonClick)

  return (
    <div className={styles.container}>
      <Divider dir="horizontal" className="mb-xl" />
      <div className={styles.buttonWrapper}>
        {hasTerciaryButton && (
          <ButtonLink onClick={onTerciaryButtonClick}>
            {terciaryButtonLabel}
          </ButtonLink>
        )}
        {hasSecondaryButton && (
          <Button
            variant="secondary"
            size={{ mobile: 'fullWidth', tablet: 'large' }}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true)
              await onSecondaryButtonClick()
              setIsLoading(false)
            }}
          >
            {secondaryButtonLabel}
          </Button>
        )}
        {hasPrimaryButton && (
          <Button
            variant="primary"
            size={{ mobile: 'fullWidth', tablet: 'large' }}
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true)
              await onPrimaryButtonClick()
              setIsLoading(false)
            }}
          >
            {primaryButtonLabel}
          </Button>
        )}
      </div>
    </div>
  )
}
