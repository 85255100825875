import { WithInjectedParams } from 'src/core/Shared/_di/types'
import {
  RoomsInventoryRepository,
  RoomsInventory,
} from 'src/core/Shared/domain/RoomsInventory.repository'
import { StorageClient } from 'src/core/Shared/infrastructure/sessionStorageClient'

interface Dependencies {
  sessionStorageClient: StorageClient
}

export const roomsInventoryRepository: WithInjectedParams<
  RoomsInventoryRepository,
  Dependencies
> = ({ sessionStorageClient }) => ({
  set(roomsInventory: RoomsInventory) {
    sessionStorageClient.set('roomsInventory', roomsInventory)
  },
  get() {
    return sessionStorageClient.get('roomsInventory') ?? {}
  },
})
