import { AnalyticsRequestGuests } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const guests: AnalyticsRequestGuests = guests => {
  const guest = guests[0]

  const linkParams = {
    tealium_event: 'personal_data_continue_as_guest',
    user_agent_property: window.navigator.userAgent,
    user_culture: window.navigator.language,
    user_email: guest.email,
    user_logged: false,
    user_name: guest.name,
    user_surname: guest.surname,
  }
  container.resolve('logger').info('Analytics/links/requests/guests', {
    linkParams,
    utag_data: window.utag_data,
  })
  window.utag.link(linkParams)
}
