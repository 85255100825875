export type Routes = (typeof routes)[keyof typeof routes]

export const routes = {
  availability: '/',
  extras: '/extras',
  personalData: '/personal-data',
  choosePayment: '/choose-payment',
  bookingConfirmation: '/booking-confirmation',
  ingenicoCallback: '/ingenico-callback',
  notFound: '/404',
  management: '/management',
  reservationDetail: '/management/:itineraryNumber',
  reservationCancellation: '/management/cancellation/:itineraryNumber',
  successCancellation: '/management/success-cancellation/',
  reservationDetailError: '/management/error',
  modifyExtras: '/management/extras',
  surveySuccess: '/management/cancellation/survey/:itineraryNumber',
  paymentCancellation: '/management/cancellation/payment/:itineraryNumber',
  reservationModification: '/management/modification/:itineraryNumber',
}
