import { AnalyticsRequestHotelById } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'

export const hotel: AnalyticsRequestHotelById = (hotel, process) => {
  const hotelParams = {
    hotel_link: true,
    hotel_name: hotel.name,
    hotel_ta_rate: hotel.review.averageRate,
    hotel_city: hotel.city,
    process,
  }
  container
    .resolve('logger')
    .info('Analytics/links/requests/hotel - hotelParams', hotelParams)
  window.utag.link(hotelParams)

  if (window.utag_data.availability_link && window.utag_data.hotel_link) {
    const bothLinksData = {
      // Room Selection Specific Variables
      ecommerce_action: 'checkout',
      ecommerce_actionField_step: '1',
      ecommerce_action_field_list_gtag: 'step 1',
      ecommerce_action_field_step_gtag: '1',
      ecommerce_action_gtag: 'begin_checkout',
    }
    container
      .resolve('logger')
      .info('Analytics/links/requests/hotel - bothLinksData', bothLinksData)
    window.utag.link(bothLinksData)
  }
}
