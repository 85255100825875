import { Loader, Modal, Text } from 'src/ui/components'
import { createModal } from 'src/ui/hooks/useModal'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'

export const TransformingReservationModal = createModal(() => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <Modal
      size="m"
      type="passive"
      mobileStyle="bottom"
      closable={false}
      data-quantum-modal="TransformingReservationModal"
    >
      <Flex direction="column" alignItems="center">
        <Text fontStyle="xl-700" color="dark" className="mb-m" as="h1">
          {trans('login_transform-reservation_title')}
        </Text>
        <Text centered fontStyle="m-300" color="dark" className="mb-xl">
          {trans('login_transform-reservation_subtitle')}
        </Text>
        <Loader variant="gradient" size="3xl" />
      </Flex>
    </Modal>
  )
})
