import {
  TabCommunication,
  TabCommunicationEvent,
  TabCommunicationEventType,
} from '../domain/TabCommunication'

export const createBroadcastTabCommunication = ({
  channelName,
}: {
  channelName: string
}): TabCommunication => {
  const channel = new BroadcastChannel(channelName)
  const listeners = new Map<
    TabCommunicationEventType,
    Set<(data: unknown) => void>
  >()

  const handleMessage = (messageEvent: MessageEvent) => {
    const event = messageEvent.data as TabCommunicationEvent
    const handlersForType = listeners.get(event.type)

    if (handlersForType) {
      handlersForType.forEach(handler => handler(event.data))
    }
  }

  const off = (
    eventType: TabCommunicationEventType,
    handler: (data: unknown) => void,
  ): void => {
    const handlersForType = listeners.get(eventType)
    if (handlersForType) {
      handlersForType.delete(handler)
      if (handlersForType.size === 0) {
        listeners.delete(eventType)
      }
    }
  }

  channel.onmessage = handleMessage

  return {
    send<T>(event: TabCommunicationEvent<T>): void {
      channel.postMessage(event)
    },

    on<T>(
      eventType: TabCommunicationEventType,
      handler: (data: T) => void,
    ): () => void {
      if (!listeners.has(eventType)) {
        listeners.set(eventType, new Set())
      }

      const handlersForType = listeners.get(eventType)!
      handlersForType.add(handler as (data: unknown) => void)
      return () => off(eventType, handler as (data: unknown) => void)
    },

    off,

    disconnect(): void {
      listeners.clear()
      channel.close()
    },
  }
}
