import { HotelRepository } from 'src/core/Hotel//domain/Hotel.repository'
import { WithInjectedParams } from 'src/core/Shared/_di/types'
import {
  CheckInCheckOutTimeDTO,
  HotelDTO,
  HotelRoomDTO,
  NearbyHotelDTO,
  NearbyHotelRequestDTO,
} from './Hotel.api.dto'
import {
  isEmpty,
  omit,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { HotelNotFoundError } from 'src/core/Hotel/domain/HotelNotFoundError'
import { HotelAdultsOnlyHasChildrenError } from 'src/core/Hotel/domain/HotelAdultsOnlyHasChildrenError'
import { AlertsDTO } from './Alerts.api.dto'
import { HotelInfoApiClient } from 'src/core/Shared/infrastructure/hotelInfoApiClient'
import { CouponValidationDTO } from './CouponValidation.api.dto'
import { Logger } from 'src/core/Shared/domain/Logger'
import { mapHotel } from './mapHotel'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { serializeRequest } from 'src/core/Availability/infrastructure/serializeRequest'
import { AvailabilityApiClient } from 'src/core/Shared/infrastructure/availabilityApiClient'
import { mapNearbyHotels } from './mapNearbyHotels'
import { CampaignDTO } from 'src/core/Hotel/infrastructure/Campaign.api.dto'
import { mapCampaign } from 'src/core/Hotel/infrastructure/mapCampaign'
import { CouponDiscountType } from 'src/core/Shared/domain/CouponDiscount'
import { mapCheckInAndCheckOutTime } from 'src/core/Hotel/infrastructure/mapCheckInAndCheckOutTime'
import { CmsApiClient } from 'src/core/Shared/infrastructure/cmsApiClient'
import { EnvManager } from 'src/core/Shared/domain/EnvManager'

interface HotelRepositoryDependencies {
  cmsApiClient: CmsApiClient
  hotelInfoApiClient: HotelInfoApiClient
  availabilityApiClient: AvailabilityApiClient
  logger: Logger
  envManager: EnvManager
}

export const apiHotelRepository: WithInjectedParams<
  HotelRepository,
  HotelRepositoryDependencies
> = ({ cmsApiClient, hotelInfoApiClient, availabilityApiClient, logger, envManager }) => ({
  getById: async (hotelId, language, hasChildren) => {
    const [{ data: roomsDTO }, { data: hotelDTO }] = await Promise.all([
      cmsApiClient.get<HotelRoomDTO[] | EmptyObject>(
        `apicontent.rooms.${encodeURIComponent(
          language,
        )}.${encodeURIComponent(hotelId)}.json`,
      ),
      cmsApiClient.get<HotelDTO | EmptyObject>(
        `apicontent.hotels.${encodeURIComponent(
          language,
        )}.${encodeURIComponent(hotelId)}.json`,
      ),
    ])

    if (isEmptyResponse(hotelDTO) || isEmptyResponse(roomsDTO)) {
      throw new HotelNotFoundError(hotelId)
    }

    if (hotelDTO.is_hotel_adults_only && hasChildren) {
      throw new HotelAdultsOnlyHasChildrenError(hotelId)
    }

    return mapHotel(hotelDTO, roomsDTO)
  },

  marketingAlerts: async (hotelId, checkIn, checkOut) => {
    try {
      const params = {
        start_date: Time.fromString(checkIn).format('YYYY-MM-DD'),
        end_date: Time.fromString(checkOut).format('YYYY-MM-DD'),
      }

      const { data: alertsDTO } = await hotelInfoApiClient.get<AlertsDTO[]>(
        `/v1/hotels/${encodeURIComponent(hotelId)}/alerts`,
        { params },
      )

      return mapAlerts(alertsDTO)
    } catch (error) {
      logger.error(new Error(`Marketing alerts error: ${error}`))
    }
  },

  hotelPromotions: async (hotelId, checkIn, checkOut) => {
    try {
      const params = {
        start_date: Time.fromString(checkIn).format('YYYY-MM-DD'),
        end_date: Time.fromString(checkOut).format('YYYY-MM-DD'),
      }

      const { data: alertsDTO } = await hotelInfoApiClient.get<AlertsDTO[]>(
        `/v1/hotels/${encodeURIComponent(hotelId)}/alerts`,
        { params },
      )

      return mapPromotions(alertsDTO)
    } catch (error) {
      logger.error(new Error(`Hotel promotions error: ${error}`))
    }
  },

  getCoupon: async (coupon, hotelId) => {
    try {
      const { data: couponValidationDTO } =
        await hotelInfoApiClient.get<CouponValidationDTO>(
          `/v1/coupons/${encodeURIComponent(coupon)}`,
          { params: { hotel_id: hotelId } },
        )

      return {
        id: couponValidationDTO.id,
        rule: couponValidationDTO.rules.map(rule => ({
          active: rule.active,
          channels: rule.channels,
          validity: {
            arrivalStartDate: rule.validity.arrivalStartDate,
            arrivalEndDate: rule.validity.arrivalEndDate,
            bookingStartDate: rule.validity.bookingStartDate,
            bookingEndDate: rule.validity.bookingEndDate,
          },
          discount: {
            value: rule.pricing.priceAdjustment,
            type: rule.pricing.type as CouponDiscountType,
          },
        }))[0],
      }
    } catch (error) {
      logger.error(error)
    }
  },

  getNearbyHotels: async request => {
    const {
      market,
      hotel,
      checkIn,
      checkOut,
      adults,
      children,
      childrenAges,
      currency,
    } = request

    const params: NearbyHotelRequestDTO = {
      market,
      id: hotel,
      start_date: Time.fromDate(checkIn).format('YYYY-MM-DD'),
      end_date: Time.fromDate(checkOut).format('YYYY-MM-DD'),
      adults,
      children,
      children_ages: childrenAges,
    }

    try {
      const { data: nearbyHotelsDTO } = await availabilityApiClient.get<
        NearbyHotelDTO[]
      >(`/v1/hotels/${encodeURIComponent(hotel)}/nearby-hotels`, {
        params,
        paramsSerializer: {
          serialize: params =>
            serializeRequest(omit(params, ['id']), childrenAges),
        },
        headers: {
          'X-CBE-Customer-Currency': currency,
        },
      })

      return mapNearbyHotels(nearbyHotelsDTO)
    } catch (error) {
      logger.error(error)
    }
  },

  getCheckInAndCheckOutTime: async hotelId => {
    try {
      const { data: checkInCheckOutTimeDTO } =
        await hotelInfoApiClient.get<CheckInCheckOutTimeDTO>(
          `/v1/hotels/${encodeURIComponent(hotelId)}/policies/time`,
        )

      return mapCheckInAndCheckOutTime(checkInCheckOutTimeDTO)
    } catch (error) {
      logger.error(error)
    }
  },

  getCampaignForCoupon: async ({
    marketprice,
    language,
    hotelId,
    promotionalCoupon,
  }) => {
    try {
      const { data: campaignDTO } = await cmsApiClient.get<
        CampaignDTO | EmptyObject
      >(
        `apicontent.campaign.${encodeURIComponent(
          marketprice,
        )}.${encodeURIComponent(language)}.${encodeURIComponent(hotelId)}.json`,
      )

      if (isEmptyResponse(campaignDTO) || isEmpty(campaignDTO.campaigns)) {
        return undefined
      }

      return mapCampaign(campaignDTO, promotionalCoupon, envManager)
    } catch (error) {
      logger.error(error)
    }
  },
})

type EmptyObject = {
  [Property in string]: never
}

const isEmptyResponse = (responseDTO: unknown): responseDTO is EmptyObject => {
  return typeof responseDTO === 'object' && isEmpty(responseDTO)
}

const mapAlerts = (alerts: AlertsDTO[]) => {
  const alertsFiltered = alerts.filter(alert => alert.type === 'MARKETING')

  return alertsFiltered.map(alert => {
    return {
      description: alert.description,
      type: alert.type,
    }
  })
}

export const mapPromotions = (alerts: AlertsDTO[]) => {
  const alertsFiltered = alerts.filter(alert => alert.type === 'HOTEL')

  return alertsFiltered.map(alert => {
    return {
      description: alert.description,
      type: 'HOTEL',
    }
  })
}
