import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { container } from 'src/core/Shared/_di'
import {
  createContext,
  ReactNode,
  FC,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react'
import {
  isDefined,
  isUndefined,
  isEmpty,
  isNull,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { CustomEvent } from 'src/core/Shared/infrastructure/eventsManager'
import { User } from 'src/core/User/domain/User'
import { useQueryService } from 'src/ui/hooks/useQuery'
import { UserErrorModal } from './UserErrorModal'
import { AuthContext, IAuthContext } from 'lib/oauth2-pkce'
import { ModalRegister, hideModal, showModal } from 'src/ui/hooks/useModal'
import { useSyncMutation } from 'src/ui/hooks/useMutation'
import {
  TabCommunicationEventType,
  UserLoggedInEvent,
} from 'src/core/Shared/domain/TabCommunication'

export interface UserContext {
  user?: User
  token?: string
}

const defaultUserContext: UserContext = {
  user: undefined,
  token: undefined,
}

export const UserContext = createContext<UserContext>(defaultUserContext)

interface UserProviderProps {
  children: ReactNode
  setIsLoading: (isLoading: boolean) => void
  postLogin?: (token: string, currencyCode?: string) => void
}

export const UserProvider: FC<UserProviderProps> = ({
  children,
  setIsLoading,
  postLogin,
}) => {
  const {
    token: authToken,
    login,
    loginInProgress,
    logOut: authLibraryLogout,
    error,
    clearError,
  } = useContext<IAuthContext>(AuthContext)

  const hasError = !isNull(error) && !isEmpty(error)
  const tabCommunication = container.resolve('tabCommunication')

  const { performMutation: handleLogin } = useSyncMutation(
    'handleLogin',
    () => {
      container.resolve('login')()
    },
  )

  const hideError = useCallback(() => {
    clearError()
    hideModal('userErrorModal')
  }, [clearError])

  const retryLoginAfterError = useCallback(() => {
    handleLogin()
    hideError()
  }, [hideError, handleLogin])

  useEffect(() => {
    if (hasError) {
      showModal('userErrorModal', {
        onClose: hideError,
        onSubmit: retryLoginAfterError,
      })
    }
  }, [retryLoginAfterError, hideError, hasError])

  const { isRouterReady, getRawParams } = useApplicationRouter()
  const [user, setUser] = useState<User | undefined>(undefined)

  const params = getRawParams(['code', 'logged', 'country'])

  const token =
    isUndefined(authToken) || isEmpty(authToken) ? undefined : authToken

  useEffect(() => {
    if (!hasError) {
      return
    }

    container
      .resolve('logger')
      .error(new Error(`Authentication error: ${error}`))
  }, [hasError, error])

  useEffect(() => {
    return container.resolve('eventsManager').on(CustomEvent.LOGIN, login)
  }, [login])

  const logout = useCallback(() => {
    setUser(undefined)
    authLibraryLogout()
  }, [authLibraryLogout])

  useEffect(() => {
    return container.resolve('eventsManager').on(CustomEvent.LOGOUT, logout)
  }, [logout])

  useEffect(() => {
    if (loginInProgress || !isRouterReady) {
      return
    }

    if (isDefined(params) && isDefined(params.code)) {
      return
    }

    setIsLoading(false)
  }, [loginInProgress, setIsLoading, isRouterReady, params])

  useEffect(() => {
    if (!isDefined(tabCommunication)) {
      return
    }

    const cleanUp = tabCommunication.on<UserLoggedInEvent>(
      TabCommunicationEventType.USER_LOGGED_IN,
      data => {
        postLogin?.(data.token, data.currencyCode)
      },
    )

    return () => {
      cleanUp()
    }
  }, [tabCommunication, postLogin])

  useEffect(() => {
    if (
      isUndefined(params) ||
      isUndefined(params.logged) ||
      !isEmpty(authToken)
    ) {
      return
    }

    handleLogin()
  }, [params, authToken, handleLogin])

  const hasToken = !isEmpty(authToken)
  const isReadyToFetchUser = isUndefined(user) && isUndefined(params?.code)

  useQueryService(
    'user',
    hasToken && isReadyToFetchUser && isRouterReady
      ? [params, authToken, user, isRouterReady]
      : null,
    async () => {
      return await container.resolve('getUser')(authToken)
    },
    {
      onSuccess: (user?: User) => {
        setUser(user)
        if (isDefined(user)) {
          container.resolve('logger').setUser(user.email)
        }
      },
    },
  )

  return (
    <UserContext.Provider value={{ user, token }}>
      {children}
      <ModalRegister id="userErrorModal" component={UserErrorModal} />
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
