import { CSSProperties, useCallback, useRef } from 'react'
import styles from './GoToTopButton.module.scss'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { ArrowUpIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ArrowUpIcon'
import { useTrans } from 'src/ui/hooks/useTrans'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  className?: string
  scrollPercent: number
  onClick: () => void
}

export const GoToTopButton = ({ className, scrollPercent, onClick }: Props) => {
  const { trans } = useTrans(['common'])
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleClick = useCallback(() => {
    onClick()
    buttonRef.current?.blur()
  }, [onClick])

  const style = {
    '--b-goToTopButton-scrollPercent': scrollPercent,
  } as CSSProperties

  return (
    <button
      ref={buttonRef}
      onClick={handleClick}
      className={classNames(styles.button, className)}
      style={style}
      aria-label={trans('go-to-top-button_aria-label')}
    >
      <Icon icon={ArrowUpIcon} size="l" color="currentColor" />
    </button>
  )
}
