import type { CallMeFormContentValues } from 'src/ui/views/_components/CallMe/CallMeFormContent'
import type { CallMeNowType } from 'src/ui/views/_components/CallMe'
import { createModal } from 'src/ui/hooks/useModal'
import { ModalV2 } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { CallMeModalHeader } from 'src/ui/views/_components/CallMe/CallMeModalHeader'
import { CallMeFormContent } from 'src/ui/views/_components/CallMe/CallMeFormContent'
import { CallMeFormFooter } from 'src/ui/views/_components/CallMe/CallMeFormFooter'
import { Form } from 'src/ui/views/_components/Form'
import { FormSubmit } from 'src/ui/views/_components/FormSubmit'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useUser } from 'src/ui/contexts/UserContext'
import { useState } from 'react'

interface Props {
  type: CallMeNowType
  analytic: object
  onSubmit: (data: object) => void
  onCancel: (data: object) => void
}

interface CallMeNowFormValues extends CallMeFormContentValues {
  userWantsCall: Decision
}

enum Decision {
  yes = 'yes',
  no = 'no',
}

export const CallMeNowModal = createModal(
  ({ type, analytic, onSubmit, onCancel }: Props) => {
    const { trans } = useTrans(['common', 'manage-reservation'])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { user } = useUser()

    const getTitle = (type: CallMeNowType) => {
      if (type === 'paymentError') {
        return trans('call-me-now-modal_title-payment-error')
      }

      return trans('call-me-now-modal_title-reservation-error')
    }

    const handleSubmit = (data: CallMeNowFormValues) => {
      setIsSubmitting(true)
      const callMeNowData = {
        ...analytic,
        isoc_call_me_now: Decision.yes,
        isoc_first_name: data.name,
        isoc_last_name: data.surname,
        user_phone: data.phone,
      }

      onSubmit(callMeNowData)
      setIsSubmitting(false)
    }

    const handleCloseModal = () => {
      onCancel({
        ...analytic,
        isoc_call_me_now: Decision.no,
      })
    }
    return (
      <ModalV2
        size="s-m"
        mobileStyle="centered"
        data-quantum-modal="CallMeNowModal"
        onClose={handleCloseModal}
        withButtons={false}
        customHeader={
          <CallMeModalHeader
            type={type}
            title={getTitle(type)}
            subtitle={
              type === 'cancellation'
                ? trans(
                    'manage-reservation:management_cancellation_survey_option_best-price_cmn_title',
                  )
                : trans('call-me-now-modal_subtitle')
            }
          />
        }
      >
        <Form
          config={{
            onSubmit: handleSubmit,
            defaultValues: {
              name: user?.givenName,
              surname: user?.familyName,
              phone: user?.phoneNumber,
            },
          }}
        >
          <Flex direction="column" gap="m" alignItems="stretch">
            <CallMeFormContent />
          </Flex>
          <CallMeFormFooter>
            <FormSubmit
              type="submit"
              size={{
                mobile: 'fullWidth',
                laptop: 'large',
              }}
              isLoading={isSubmitting}
            >
              {trans('call-me-back-modal_button')}
            </FormSubmit>
          </CallMeFormFooter>
        </Form>
      </ModalV2>
    )
  },
)
