import { ComponentProps, FC } from 'react'

import styles from './FullScreenLoader.module.scss'
import { PageLoader } from 'src/ui/components/molecules/PageLoader'

type Props = ComponentProps<typeof PageLoader>

export const FullScreenLoader: FC<Props> = props => (
  <div className={styles.container} data-testid="full-screen-loader">
    <PageLoader {...props} />
  </div>
)
