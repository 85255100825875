import { WithInjectedParams } from 'src/core/Shared/_di/types'
import { StorageClient } from 'src/core/Shared/infrastructure/sessionStorageClient'
import { ApplicationExperience } from 'src/ui/hooks/useApplicationExperience'

interface ApplicationExperienceStorageRepositoryDependencies {
  sessionStorageClient: StorageClient
}

export interface ApplicationExperienceStorageRepository {
  setApplicationExperience: (experience: ApplicationExperience) => void
  getApplicationExperience: () => ApplicationExperience
}

export const applicationExperienceStorageRepository: WithInjectedParams<
  ApplicationExperienceStorageRepository,
  ApplicationExperienceStorageRepositoryDependencies
> = ({ sessionStorageClient }) => ({
  setApplicationExperience: (experience: ApplicationExperience) => {
    sessionStorageClient.set('applicationExperience', { experience })
  },
  getApplicationExperience: () =>
    sessionStorageClient.get<{ experience: ApplicationExperience }>(
      'applicationExperience',
    )?.experience || 'default',
})
