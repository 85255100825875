import { QueryParamValue } from '../hooks/useApplicationRouter/useApplicationRouter.model'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'

const cmsBaseUrl = () => process.env.NEXT_PUBLIC_CMS_BASE_URL

export const generalConditions = (store?: string) => {
  return `${cmsBaseUrl()}/${getStore(store)}/mybarcelo/condiciones-generales/?redirect=true&lang=true`
}

export const termsAndConditions = (store?: string) => {
  return `${cmsBaseUrl()}/${getStore(store)}/informacion-general/terminos-condiciones/?redirect=auto`
}

export const privacyPolicy = (store?: string) => {
  return `${cmsBaseUrl()}/${getStore(store)}/informacion-general/politica-privacidad/?redirect=true&lang=true`
}

export const hotelList = () => {
  return `${cmsBaseUrl()}/es-es/hoteles/?redirect=true&lang=true`
}

export const barceloHome = () => {
  if (isFeatureFlagEnabled('FF_POC_SET_COOKIES')) {
    return 'https://www-staging.barcelo.com/es-es/bhg/ciam-login/'
  }

  return `${cmsBaseUrl()}/?redirect=true`
}

export const hotelListWithDates = (
  startDate: QueryParamValue,
  endDate: QueryParamValue,
) => {
  return `${cmsBaseUrl()}/es-es/listado-hoteles/?startDate=${startDate}&endDate=${endDate}/?redirect=true&lang=true`
}

export const redirectTo404 = () => {
  return `${cmsBaseUrl()}/es-es/404/?redirect=true&lang=auto`
}

export const userPersonalData = (store?: string) => {
  return `${cmsBaseUrl()}/${getStore(store)}/mybarcelo/area-privada/?redirect=true&lang=true#mydata`
}

export const userMyReservations = (store?: string) => {
  return `${cmsBaseUrl()}/${getStore(store)}/mybarcelo/area-privada/?redirect=true&lang=true#mis-reservas`
}

export const cookies = (store?: string) => {
  return `${cmsBaseUrl()}/${getStore(store)}/informacion-general/politica-cookies/?redirect=true&lang=true`
}

export const legalTerms = (store?: string) => {
  return `${cmsBaseUrl()}/${getStore(store)}/informacion-general/?redirect=true&lang=true`
}

export const legalNotice = (store?: string) => {
  return `${cmsBaseUrl()}/${getStore(store)}/informacion-general/aviso-legal/?redirect=true&lang=true`
}

function getStore(store?: string) {
  return store?.toLowerCase() || 'es-es'
}
