import { FC, ReactNode, useRef } from 'react'
import styles from './ModalContentV2.module.scss'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  className?: string
  isMobileFullscreen: boolean
  children: ReactNode
}

export const ModalContentV2: FC<Props> = ({
  className,
  isMobileFullscreen,
  children,
}) => {
  const modalContent = useRef<HTMLDivElement>(null)

  return (
    <section
      ref={modalContent}
      className={classNames(
        styles.modalContent,
        isMobileFullscreen && styles.modalContent_isMobileFullscreen,
        className,
      )}
    >
      {children}
    </section>
  )
}
