import { FC, useMemo } from 'react'
import { Text } from 'src/ui/components'
import { BLoader } from 'src/ui/components/atoms/BLoader/BLoader'
import { useTrans } from 'src/ui/hooks/useTrans'

import styles from './PageLoader.module.scss'

interface Props {
  mainText?: string
  secondaryText?: string
}

const nbspUnicode = '\u00A0'

export const PageLoader: FC<Props> = ({ mainText, secondaryText }) => {
  const { trans } = useTrans(['common'])
  const defaultText = useMemo(() => {
    if (typeof window === 'undefined') {
      return nbspUnicode
    }
    return trans('loader_loading_title', { defaultValue: nbspUnicode })
  }, [trans])

  return (
    <div className={styles.container} aria-live="polite">
      <BLoader />
      {mainText ? (
        <>
          <Text className={styles.mainText} fontStyle="2xl-700" color="dark">
            {mainText}
          </Text>
          <Text className={styles.secondaryText} fontStyle="m-300" color="dark">
            {secondaryText}
          </Text>
        </>
      ) : (
        <Text className={styles.defaultText} fontStyle="m-500">
          {defaultText}
        </Text>
      )}
    </div>
  )
}
