import { CSSProperties, FC } from 'react'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Color, IconSize } from 'src/ui/styles/settings'

type Size = { height?: IconSize; width?: IconSize } | IconSize

export type ViewBoxType = 'square' | 'bigSquare' | 'portrait' | 'landscape'

interface Props {
  size: Size
  color?: Color | 'currentColor' | 'none'
  icon: FC<{
    color: string
  }>
  rotate?: number
  iconTitle?: string
  testId?: string
  viewBox?: ViewBoxType
  ariaLabel?: string
  className?: string
  'data-testid'?: string
  ariaHidden?: boolean
}

export const Icon: FC<Props> = ({
  icon: SVGIcon,
  color = 'icon-dark',
  size,
  rotate,
  iconTitle,
  testId = 'icon',
  viewBox = 'square',
  ariaLabel,
  className,
  'data-testid': dataTestId,
  ariaHidden,
}) => {
  const styleProps = {
    height:
      typeof size === 'object'
        ? `var(--b-icon-${size.height})`
        : `var(--b-icon-${size})`,
    width:
      typeof size === 'object'
        ? `var(--b-icon-${size.width})`
        : `var(--b-icon-${size})`,
    transform: rotate && `rotate(${rotate}deg)`,
  } as CSSProperties

  const getViewBox = () => {
    if (viewBox === 'square') {
      return '0 0 24 24'
    }

    if (viewBox === 'bigSquare') {
      return '0 0 32 32'
    }

    if (viewBox === 'portrait') {
      return '0 0 18 22'
    }

    return '0 0 40 24'
  }

  const getIconColor = () => {
    if (color === 'currentColor') {
      return 'currentcolor'
    }

    if (color === 'none') {
      return ''
    }

    return `var(--b-color-${color})`
  }

  return (
    <Flex className={className} data-testid={dataTestId}>
      <svg
        aria-label={ariaLabel}
        data-testid={testId}
        viewBox={getViewBox()}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        style={styleProps}
        aria-hidden={!ariaLabel || ariaHidden}
      >
        <title>{iconTitle}</title>
        <SVGIcon color={getIconColor()} />
      </svg>
    </Flex>
  )
}
