import { AnalyticsActionsExtrasContinueWithExtras } from 'src/core/Shared/domain/Analytics'
import {
  flattenDeep,
  head,
  isDefined,
  isUndefined,
  uniq,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { container } from 'src/core/Shared/_di'
import {
  isAppliedExtraPerGuest,
  isAppliedExtraPerUnit,
  isNotAppliedExtra,
} from 'src/core/Shared/domain/Reservation.model'
import { isAppliedExtraPerUnitOrPerGuest } from 'src/core/Shared/domain/Extra.model'
import { buildUtagDataForReservation } from 'analytics/links/requests/preReservation'

export const continueWithExtras: AnalyticsActionsExtrasContinueWithExtras = (
  reservation,
  process,
) => {
  const extra = head(
    reservation.roomStays
      .find(roomStay => roomStay.extras?.find(isDefined))
      ?.extras?.filter(isDefined),
  )

  if (isUndefined(extra)) {
    return
  }

  const extraNames = uniq(
    flattenDeep(
      reservation.roomStays.map(roomStay => {
        return roomStay.extras
          ?.map(extra => {
            if (isDefined(extra) && isAppliedExtraPerUnitOrPerGuest(extra)) {
              return extra.name
            }
          })
          .filter(isDefined)
      }),
    ),
  )

  const extraIds = uniq(
    flattenDeep(
      reservation.roomStays.map(roomStay => {
        return roomStay.extras.map(extra => {
          return extra.id
        })
      }),
    ),
  )

  const totalUnits = extraIds.map(extraId => {
    return flattenDeep(
      reservation.roomStays.map(roomStay => {
        return roomStay.extras
          ?.filter(extra => extra.id === extraId)
          .map(extra => {
            if (isUndefined(extra) || isNotAppliedExtra(extra)) {
              return
            }

            if (isDefined(extra) && isAppliedExtraPerUnit(extra)) {
              return extra.quantity
            }
            if (isDefined(extra) && isAppliedExtraPerGuest(extra)) {
              return extra.adultsQuantity + extra.childrenQuantity
            }
          })
          .filter(isDefined)
      }),
    ).reduce((a, b) => a + b, 0)
  })

  const pricePerExtra = extraIds.map(extraId => {
    return flattenDeep(
      reservation.roomStays.map(roomStay => {
        return roomStay.extras
          ?.filter(extra => extra.id === extraId)
          .map(appliedExtra => {
            if (
              isDefined(appliedExtra) &&
              isAppliedExtraPerUnitOrPerGuest(appliedExtra)
            ) {
              return appliedExtra.summary.grossPrice.value
            }
          })
          .filter(isDefined)
      }),
    ).reduce((a, b) => a + b, 0)
  })

  const totalPrice = pricePerExtra.reduce((a, b) => a + b, 0)

  const linkParams = {
    ga4_event_name: 'step2_button_extras',
    event: 'step2_button_extras',
    event_category: 'extras',
    event_action: 'continue_with_extras',
    event_label: 'user goes to step2 with extras',
    booking_extras: extraNames,
    booking_extras_id: extraIds,
    booking_extras_units: totalUnits,
    booking_extras_price: pricePerExtra,
    booking_extras_total_price: totalPrice,
    booking_extras_currency:
      isDefined(extra) && isAppliedExtraPerUnitOrPerGuest(extra)
        ? extra.summary.grossPrice.currency
        : undefined,
    process,
    ...buildUtagDataForReservation(reservation),
  }
  container
    .resolve('logger')
    .info('Analytics/links/actions/extras/continueWithExtras', {
      linkParams,
      utag_data: window.utag_data,
    })
  window.utag.link(linkParams)
}
